import { computed, observable } from 'mobx';

const floor = (value) => {
    return Math.floor(value * 100) / 100;
}

const round = (value) => {
    return Math.round(value * 100) / 100;
}

const ceil = (value) => {
    return Math.ceil(value * 100) / 100;
}

export default class ProductModel implements INewProduct {
    static readonly VAT_CLASSES: number[] = [
        0,
        10,
        14,
        24
    ];

    static readonly SERVICE_FEE_PERCENT = 0.1;

    @observable type: ProductType = 'coupon';

    @observable id: Null<number> = null;
    @observable merchantId: Null<number> = null;
    @observable name: string = '';
    @observable urlName: string = '';
    @observable description: string = '';
    @observable short: Null<string> = '';
    @observable image: Null<string> = null;
    @observable deliveryUrl: Null<string> = null;

    @observable productPrice: number = 0;
    @observable totalPrice: number = 0;
    @observable serviceFee: number = 0;
    @observable basePrice: number = 0;
    @observable taxValue: number = 0;

    @observable taxClass: number = 3;
    @observable isHidden: Flag = 0;
    @observable isPublished: Flag = 1;
    @observable showServiceFee: Flag = 1;

    @observable uiMajorColor: string = '#170525';
    @observable uiMinorColor: string = '#9F2B93';
    @observable uiMajorTextColor: string = '#FFFFFF';
    @observable uiMinorTextColor: string = '#FFFFFF';

    @observable tpSuccessTitle: string = '';
    @observable tpSuccessText: string = '';
    @observable tpSuccessInstructionsText: string = '';
    @observable tpSuccessInstructionsButton: string = '';
    @observable tpShowShare: number = 1;
    @observable tpShowReceipt: number = 1;
    @observable tpShowInstructions: number = 1;

    @observable smsText: string = '';
    @observable imageBody: Null<string|ArrayBuffer> = null;
    @observable imageName: Null<string> = null;

    @computed get taxText() {
        return `Hinta sisältää ${ProductModel.VAT_CLASSES[this.taxClass]}% ALV`;
    }

    getTaxPercent = () => {
        return ProductModel.VAT_CLASSES[this.taxClass] / 100;
    }

    getBasePrice = (total) => {
        return floor(total / (1 + this.getTaxPercent())); // productPrice + serviceFee without vats
    };

    getProductPrice = (total) => {
        return round(this.getBasePrice(total) / (1 + ProductModel.SERVICE_FEE_PERCENT) * (1 + this.getTaxPercent())); // Product price with Vat
    };

    getServiceFee = () => {
        return round(this.productPrice * ProductModel.SERVICE_FEE_PERCENT); // Service Fee with Vat
    };

    getTaxValue = () => {
        return round(this.getRecalculatedTotal()- this.getRecalculatedTotal() / (1 + this.getTaxPercent()));
    };

    getRecalculatedTotal = () => {
        return this.productPrice + this.serviceFee;
    };

    setPrices = (total) => {
        this.basePrice = this.getBasePrice(total);
        this.productPrice = this.getProductPrice(total);
        this.serviceFee = this.getServiceFee();
        this.taxValue = this.getTaxValue();
        this.totalPrice = this.getRecalculatedTotal();
    }
}