import './ProductTypeSelect.css';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ProductModel from '../../models/ProductModel';


interface SelectProps {
    product: ProductModel;
}

const ProductTypeSelect = ({ product }: SelectProps) => {
    const { t } = useTranslation();

    const [showOptions, setShowOptions] = useState(false);

    const options = [
        { type: 'coupon', label: t("editPage.coupon") },
        { type: 'ticket', label: t("editPage.ticket") },
    ];

    const setProductType = (type) => {
        product.type = type;
    };

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    const handleSelection = (type: string) => {
        setShowOptions(false);
        setProductType(type)
    };

    return (
        <>
            <div
                className={'select no-copy-text'}
                onClick={() => toggleOptions()}
                id={'select'}
            >{t(`editPage.${product.type}`)}</div>

            {showOptions &&
                <div className={'select select-options'}>
                    {options.map(i =>
                            <span
                                className={product.type === i.type ? 'option option_active' : 'option'}
                                onClick={() => handleSelection(i.type)}
                            >{i.label}</span>
                    )}
                </div>
            }
        </>
    );
};

export default ProductTypeSelect;