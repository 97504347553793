import { action, observable } from 'mobx';

interface Error {
    code: number;
    message: string;
    data?: string;
}

export class ErrorStore {
    @observable error: Error|null;

    constructor() {
        this.error = null;
    }

    @action
    addError = (error: Error) => {
        this.error = error;
    }

    @action
    clearError = () => {
        this.error = null;
    }
}

const errorStore = new ErrorStore();

export default errorStore;
