import './SmsPage.css';

import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-autosize-textarea';

import { prepareColorForSend, setColorScheme } from '../../services/productService';
import Spinner from '../../components/Spinner';
import MainStore from '../../store/mainStore';
import SmsTextEditor from '../../components/SmsTextEditor/SmsTextEditor';
import SwitcherCheckbox from '../../components/SwitcherCheckbox';

interface ThisRouteParams {
    id: string
}

interface SmsPageProps extends RouteComponentProps<ThisRouteParams> {
    stores: MainStore
}

const COUPON_SMS_TEXT = 'Kiitos ostoksestasi!\nTässä on koodisi: @code\nLisätietoja löydät täältä: @site';
const TICKET_SMS_TEXT = 'Kiitos ostoksestasi!\nLisätietoja löydät täältä: @site';

const SmsPage = ({ stores, match, history }: SmsPageProps) => {
    const { t } = useTranslation();
    const { id } = match.params;
    const { productStore } = stores;
    const { merchant } = stores.merchantStore;
    const product = productStore.product;

    product.smsText = product.type === 'coupon' ? COUPON_SMS_TEXT : TICKET_SMS_TEXT;

    useEffect((): void|(() => void) => {
        productStore.setProductFromLocalStorage('editedProduct');
        setColorScheme(productStore.product);
        history.push(`/products/${product.id}/thanks/sms-text`);
    }, [productStore]);

    const [isDataLoading, setIsDataLoading] = useState(false);

    const saveProduct = async () => {
        setIsDataLoading(true);

        const commonFields = [
            product.type,
            product.name,
            product.description,
            product.totalPrice,
            product.basePrice,
            product.serviceFee,
            product.productPrice,
            product.taxValue,
            product.taxClass,
            product.deliveryUrl || '',
            product.isHidden,
            product.isPublished,
            product.showServiceFee,
            prepareColorForSend(product.uiMajorColor),
            prepareColorForSend(product.uiMinorColor),
            prepareColorForSend(product.uiMajorTextColor),
            prepareColorForSend(product.uiMinorTextColor),
            product.tpSuccessTitle,
            product.tpSuccessText,
            product.tpSuccessInstructionsText,
            product.tpSuccessInstructionsButton,
            product.tpShowShare,
            product.tpShowReceipt,
            product.tpShowInstructions,
            product.smsText,
            product.imageName!,
            product.imageBody
        ];

        try {
            // @ts-ignore
            const response = product.id ? await productStore.editProduct(product.id!, ...commonFields) : await productStore.addProduct(merchant.id!, ...commonFields);

            setIsDataLoading(false);
            history.push('/products/', {id: response.id});
        } catch {
            setIsDataLoading(false);
        }
    };

    return (
        <div className="lift-body" id="sms-page">
            {isDataLoading &&
                <Spinner />
            }

            <div className="fix-btn-wrap">
                <button
                    className="btn btn-outline-dark btn-rounded aside-btn"
                    onClick={() => history.push(`/products/${id}/thanks`)}
                >{t("button.back")}</button>
            </div>

            <div className="fix-btn-wrap fix-btn-wrap_right">
                <button
                    className="btn btn-success btn-rounded aside-btn"
                    onClick={saveProduct}
                >{t("button.save")}</button>
            </div>

            <div className="sm-lift sms-page">
                {product.type === 'coupon' &&
                    <div className="url-editor">
                        <h3 className="editor-text">Add delivery url for your product</h3>
                        <TextareaAutosize
                            defaultValue={product.deliveryUrl ? product.deliveryUrl : 'https://'}
                            onChange={(e) => product.deliveryUrl = e.currentTarget.value}
                            className="text_editable url_editable"
                            autoFocus={!product.deliveryUrl}
                        />
                    </div>
                }

                <SmsTextEditor />

                <div className="isPublished-editor">
                    <h3 className="isPublished-editor-text">{t("smsPage.publish")}</h3>

                    <SwitcherCheckbox prodProp={'isPublished'}/>
                </div>
            </div>
        </div>
    );
};

export default inject('stores')(observer(SmsPage));
