import './SmsTextEditor.css';

import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import MainStore from '../../store/mainStore';

interface SmsTextEditorProps {
    stores?: MainStore;
}

const SmsTextEditor = ({ stores }: SmsTextEditorProps) => {
    const { t } = useTranslation();
    const { productStore } = stores!;
    const product = productStore.product;

    let smsTextRef:any = React.useRef();

    const placeBlank = (cursorPosition: number, blankText: string) => {
        if (cursorPosition === 0) {
            product.smsText = product.smsText.slice(0, 1) !== ' ' ? blankText + ' ' + product.smsText : blankText + product.smsText;
            return;
        }

        if (cursorPosition === product.smsText.length) {
            product.smsText = product.smsText.slice(-1) !== ' ' ? product.smsText + ' ' + blankText : product.smsText + blankText;
            return;
        }

        const firstPart = product.smsText.slice(0, cursorPosition);
        const secondPart = product.smsText.slice(cursorPosition);
        const prevSymbol = firstPart.slice(cursorPosition - 1);
        const nextSymbol = secondPart.slice(0, 1);
        if (prevSymbol !== ' ') {
            blankText = ' ' + blankText;
        }
        if (nextSymbol !== ' ') {
            blankText = blankText + ' ';
        }
        product.smsText = firstPart + blankText + secondPart;
    }

    const getTextLength = () => {
        let textLength = product.smsText.length;
        if (product.smsText.match('@code')) {
            textLength += 7;
        }
        if (product.smsText.match('@site')) {
            textLength += 65;
        }
        return textLength;
    }

    const smsLength = getTextLength();

    return (
        <div className="sms-editor">

            <h3 className="editor-text">{t("smsPage.smsTitle")}</h3>

            <div className="d-flex">

                <div className="sms-editor-wrap">
                    <div id='sms-length-counter' className="sms-length-counter">{smsLength}/160</div>
                    <TextareaAutosize
                        value={product.smsText}
                        onChange={(e) => {
                            product.smsText = e.currentTarget.value;
                        }}
                        className="text_editable sms_editable"
                        autoFocus={!!product.deliveryUrl}
                        ref={smsTextRef}
                    />
                </div>

                <div className="sms-editor-buttons">
                    <button
                        className="sm-button sms-editor-btn"
                        onMouseDown={(e)=> {
                            placeBlank(smsTextRef.current.selectionStart, '@site');
                        }}
                    >{t("smsPage.placeSite")}</button>

                    {product.type === 'coupon' &&
                        <button
                            className="sm-button sms-editor-btn"
                            onMouseDown={(e)=> {
                                placeBlank(smsTextRef.current.selectionStart, '@code');
                            }}
                        >{t("smsPage.placeCode")}</button>
                    }
                </div>
            </div>

            {smsLength > 160 &&
                <div className="alert alert-warning">
                    Your sms text exceeds the sms length (160 symbols). The text will be divided into {Math.ceil(smsLength/160)} sms.
                </div>
            }
        </div>
    )
};

export default inject('stores')(observer(SmsTextEditor));