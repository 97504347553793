import UiStore from './uiStore';
import ProductStore from './productStore';
import MerchantStore from './merchantStore';
import errorStore, { ErrorStore } from './errorStore';

export default class MainStore {
    uiStore: UiStore;
    productStore: ProductStore;
    merchantStore: MerchantStore;
    errorStore: ErrorStore;

    constructor() {
        this.uiStore = new UiStore();
        this.productStore = new ProductStore();
        this.merchantStore = new MerchantStore();
        this.errorStore = errorStore;
    }
}
