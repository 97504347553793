import {action, observable} from 'mobx';

export default class MerchantModel extends Object implements INewMerchant {
    @observable id: Null<number> = null;
    @observable logo: string = '';
    @observable name: string = '';
    @observable showServiceAgreement: Flag = 0;
    @observable uiMajorColor: string = '';
    @observable uiMinorColor: string = '';
    @observable uiMajorTextColor: string = '';
    @observable uiMinorTextColor: string = '';
    @observable urlName: string = '';

    @action setData(merchant: IMerchant) {
        for (let prop in merchant) {
            if (this.hasOwnProperty(prop)) {
                this[prop] = merchant[prop];
            }
        }

        if (this.logo) {
            this.logo += `?ts=${Date.now()}`;
        } else {
            this.logo = '/android-chrome-192x192.png';
        }
    };
}
