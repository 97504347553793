import './Receipt.css';

import React from 'react';
import ProductModel from '../../models/ProductModel';
import MerchantModel from '../../models/MerchantModel';

interface ReceiptProps {
    product: ProductModel;
    merchant: MerchantModel;
}

const Receipt = ({ product, merchant }: ReceiptProps) => {
    return (
        <div className="sm-order-receipt-wrap" id="capture">
            <div className="sm-order-receipt">
                <div className="sm-order-receipt-logo"
                     style={{backgroundImage: `url(${merchant.logo})`}} />

                <div className="sm-order-receipt-title">{merchant.name} nettikauppa</div>

                <div className="sm-order-receipt-line">
                    <span>24.4.2020</span>
                    <span>10:07</span>
                </div>

                <div className="sm-order-receipt-separator" />

                <div className="sm-order-receipt-line">
                    <span>{product.name}</span>
                    <span>{product.showServiceFee ? product.productPrice : product.totalPrice}&euro;</span>
                </div>

                {!!product.showServiceFee &&
                <div className="sm-order-receipt-line">
                    <span>Mobiilimaksulisä</span>
                    <span>{product.serviceFee}&euro;</span>
                </div>
                }

                <div className="sm-order-receipt-separator" />

                <div className="sm-order-receipt-line">
                    <span>ALV {ProductModel.VAT_CLASSES[product.taxClass]}% (Sis. hintaan)</span>
                    <span>{product.taxValue}</span>
                </div>

                <div className="sm-order-receipt-separator" />

                <div className="sm-order-receipt-line">
                    <span>YHTEENSÄ</span>
                    <span>{product.totalPrice}&euro;</span>
                </div>

                <div className="sm-order-receipt-button">
                    <button type="button" id="capture_btn" className="sm-button sm-button-action sm-button-capture">Tallenna</button>
                </div>
            </div>
        </div>
    );
};

export default Receipt;