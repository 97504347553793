import '../SettingsPage/SettingsPage.css';

import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import { WithTranslation, withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import MainStore from '../../store/mainStore';

import {getAuth0Client} from '../../react-auth0-spa';
import config from '../../config';

interface ThisComponentProps extends RouteComponentProps<any>, WithTranslation {
    stores: MainStore
}

const MerchantRegistration = ({ stores, history }: ThisComponentProps) => {
    const {merchantStore} = stores;
    const [authId, setAuthId] = useState('');
    const [name, setName] = useState('');
    const [urlName, setUrlName] = useState('');

    useEffect(() => {
        async function initUser() {
            const auth0 = await getAuth0Client();
            const user = await auth0.getUser();

            setName(user.name);
            setAuthId(user.sub)
        }

        initUser();
    }, []);

    useEffect(() => {
        async function updateUrlName() {
            const result = await merchantStore.previewMerchantUrlName(name);
            setUrlName(result);
        }

        name && name.length > 3 ? updateUrlName() : setUrlName('');
    }, [name]);

    const registerMerchant = async (e) => {
        if (e) {
            e.preventDefault();
        }
        try {
            const merchant = await merchantStore.registerMerchant(authId, name);
            if (merchant) {
                merchantStore.setMerchant(merchant);
                history.push('/products/');
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <form onSubmit={registerMerchant}>
            <div className="settings-list">
                <section className="setting" id="general">
                    <h4 className="setting-title">Welcome to SIRU!</h4>
                    <p className="setting-description">
                        You are only one step away from creating your personal shop.
                        Please verify the data below and click "Register!" to continue with creating your first product!
                    </p>

                    <div className="setting-type">
                        <label className="setting-name" htmlFor="company_name">Shop name:</label>
                        <input type="text"
                               className="setting-input"
                               placeholder="Best shop ever"
                               value={name || ''}
                               required={true}
                               minLength={4}
                               maxLength={100}
                               onChange={(e)=>setName(e.target.value)}
                        />
                    </div>

                    <div className="setting-type">
                        <label className="setting-name" htmlFor="company_name">Link preview:</label>
                        {config.site.url}/<b>{urlName}</b>
                    </div>
                    <button type="submit" className="btn btn-lg btn-secondary">Register!</button>
                </section>

            </div>
        </form>
    );
};

export default inject('stores')(observer(withTranslation()(MerchantRegistration)));
