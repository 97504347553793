import './ThankPage.css';
import '../ProductEditPage/ProductEditPage.scss';

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import MainStore from '../../store/mainStore';
import { setColorScheme } from '../../services/productService';
import CouponPage from '../CouponPage';
import TicketPage from '../TicketPage';

interface ThisRouteParams {
    id: string,
}

interface ThankPageProps extends RouteComponentProps<ThisRouteParams> {
    stores: MainStore,
}

const ThankPage = ({ stores, match, history }: ThankPageProps) => {
    const { t } = useTranslation();
    const { id } = match.params;
    const { productStore } = stores;
    const product = productStore.product;

    useEffect((): void|(() => void) => {
        productStore.setProductFromLocalStorage('editedProduct');
        setColorScheme(productStore.product);
        history.push(`/products/${product.id}/thanks`);
    }, [productStore]);

    const onNextClick = () => {
        localStorage.setItem('editedProduct', JSON.stringify(product));
        history.push(`${match.url}/sms-text`);
    };

    return (
        <div className="lift-body">
            <div className="fix-btn-wrap">
                <button
                    className="btn btn-outline-dark btn-rounded aside-btn"
                    onClick={() => history.push(`/products/${id}`)}
                >{t("button.back")}</button>
            </div>

            <div className="fix-btn-wrap fix-btn-wrap_right">
                <button
                    className="btn btn-success btn-rounded aside-btn"
                    onClick={() => onNextClick()}
                >{t("button.next")}</button>
            </div>

            {product.type === 'coupon' &&
                <CouponPage />
            }

            {product.type === 'ticket' &&
                <TicketPage />
            }
        </div>
    );
};

export default inject('stores')(observer(ThankPage));