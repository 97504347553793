import { action, observable } from 'mobx';
import api from './api';
import ProductModel from '../models/ProductModel';
import MerchantModel from '../models/MerchantModel';
import { getColor } from '../services/productService';
import { getImageBody } from '../services/imageService';

const INIT_PROD_DESCRIPTION = 'There is a description for your product. Here you can share information about your product, ' +
    'indicate size or another parameters, describe usability and so on. You can stress why customers should choose you offer, ' +
    'what advantages the product has.<br /><br />' +
    'Also you could add any information you want.<br /><br />' +
    'Try to attract a customer with your description!';
const INIT_PROD_NAME = 'Title of your product';
const INIT_PROD_PRICE = 9.09;
const INIT_PROD_TOTAL_PRICE = 10;
const INIT_PROD_SERVICE_FEE = 0.91;
const INIT_PROD_BASE_PRICE = 8.06;
const INIT_PROD_TAX_VALUE = 1.94;
const INIT_IMAGE_NAME = 'default-product.jpg';
const INIT_IMAGE = `/images/default-product.jpg`;
const INIT_TP_SUCCESS_TITLE = 'Maksu onnistui!';
const INIT_TP_SUCCESS_TEXT = 'Saat kohta katselukoodin myös tekstiviestinä.';
const INIT_TP_SUCCESS_INSTRUCTIONS_TEXT = '<b>1.' +
    '<br /><br />Siirry sivuille ostamasi tuotteen kohdalle.' +
    '<br /><br />2.' +
    '<br /><br />Valitse "lunasta koodi" ja syötä ostamasi koodi.\n' +
    '<br /><br />3.' +
    '<br /><br />Rekisteröidy uutena käyttäjänä palveluun!</b>';
const INIT_TP_SUCCESS_INSTRUCTIONS_BUTTON = 'KATSOMAAN!';

export default class ProductStore {
    private api: typeof api;
    @observable product: ProductModel;

    constructor() {
        this.api = api;
        this.product = new ProductModel();
    }

    @action
    setProperty = (prop, value) => {
       this.product[prop] = value;
    };

    @action
    getProducts = async () => {
        return await this.api.getProducts();
    };

    @action
    getProduct = async (id) => {
        return await this.api.getProduct(id);
    };

    @action
    setDefaultProduct = async (merchant: MerchantModel) => {
        this.product.id = null;
        this.product.name = INIT_PROD_NAME;
        this.product.description = INIT_PROD_DESCRIPTION;
        this.product.image = INIT_IMAGE;

        this.product.productPrice = INIT_PROD_PRICE;
        this.product.totalPrice = INIT_PROD_TOTAL_PRICE;
        this.product.serviceFee = INIT_PROD_SERVICE_FEE;
        this.product.basePrice = INIT_PROD_BASE_PRICE;
        this.product.taxValue = INIT_PROD_TAX_VALUE;

        this.product.uiMajorColor = `#${merchant.uiMajorColor}`;
        this.product.uiMinorColor = `#${merchant.uiMinorColor}`;
        this.product.uiMajorTextColor = `#${merchant.uiMajorTextColor}`;
        this.product.uiMinorTextColor = `#${merchant.uiMinorTextColor}`;

        this.product.tpSuccessTitle = INIT_TP_SUCCESS_TITLE;
        this.product.tpSuccessText = INIT_TP_SUCCESS_TEXT;
        this.product.tpSuccessInstructionsText = INIT_TP_SUCCESS_INSTRUCTIONS_TEXT;
        this.product.tpSuccessInstructionsButton = INIT_TP_SUCCESS_INSTRUCTIONS_BUTTON;

        this.product.imageName = INIT_IMAGE_NAME;
        this.product.imageBody = await getImageBody(INIT_IMAGE);
    };

    @action
    setCustomProduct = (product: IProduct) => {
        for (let prop in product) {
            this.product[prop] = product[prop];
        }

        this.product.productPrice = +this.product.productPrice;
        this.product.totalPrice = +this.product.totalPrice;
        this.product.serviceFee = +this.product.serviceFee;
        this.product.basePrice = +this.product.basePrice;
        this.product.taxValue = +this.product.taxValue;

        this.product.uiMajorColor = product.uiMajorColor ? `#${product.uiMajorColor}` : this.product.uiMajorColor;
        this.product.uiMinorColor = product.uiMinorColor ? `#${product.uiMinorColor}` : this.product.uiMinorColor;
        this.product.uiMajorTextColor = product.uiMajorTextColor ? `#${product.uiMajorTextColor}` : this.product.uiMajorTextColor;
        this.product.uiMinorTextColor = product.uiMinorTextColor ? `#${product.uiMinorTextColor}` : this.product.uiMinorTextColor;

        this.product.imageName = product.image;
        this.product.imageBody = null;
    };

    @action
    setProductFromLocalStorage = (productType: string) => {
        const storageProduct: StorageProduct = JSON.parse(localStorage.getItem(productType)!);
        console.log(storageProduct);
        for (let prop in storageProduct) {
            this.product[prop] = storageProduct[prop];
        }
    };

    @action
    saveProductColorScheme = () => {
        this.product.uiMajorColor = getColor('--major-color');
        this.product.uiMinorColor = getColor('--minor-color');
        this.product.uiMajorTextColor = getColor('--major-text-color');
        this.product.uiMinorTextColor = getColor('--minor-text-color');
    };

    @action
    editProduct = async (
        id: number,
        type: ProductType,
        name: string,
        description: string,
        totalPrice: number,
        basePrice: number,
        serviceFee: number,
        productPrice: number,
        taxValue: number,
        taxClass: number,
        deliveryUrl: string,
        isHidden: number,
        isPublished: number,
        showServiceFee: number,
        uiMajorColor: string,
        uiMinorColor: string,
        uiMajorTextColor: string,
        uiMinorTextColor: string,
        tpSuccessTitle: string,
        tpSuccessText: string,
        tpSuccessInstructionsText: string,
        tpSuccessInstructionsButton: string,
        tpShowShare: number,
        tpShowReceipt: number,
        tpShowInstructions: number,
        smsText: string,
        image: string,
        imageBody: string|null|ArrayBuffer = null
    ) => {
        return await this.api.editProduct(
            id,
            type,
            name,
            description,
            totalPrice,
            basePrice,
            serviceFee,
            productPrice,
            taxValue,
            taxClass,
            deliveryUrl,
            isHidden,
            isPublished,
            showServiceFee,
            uiMajorColor,
            uiMinorColor,
            uiMajorTextColor,
            uiMinorTextColor,
            tpSuccessTitle,
            tpSuccessText,
            tpSuccessInstructionsText,
            tpSuccessInstructionsButton,
            tpShowShare,
            tpShowReceipt,
            tpShowInstructions,
            smsText,
            image,
            imageBody
        );
    };

    @action
    addProduct = async (
        merchantId: number,
        type: ProductType,
        name: string,
        description: string,
        totalPrice: number,
        basePrice: number,
        serviceFee: number,
        productPrice: number,
        taxValue: number,
        taxClass: number,
        deliveryUrl: string,
        isHidden: number,
        isPublished: number,
        showServiceFee: number,
        uiMajorColor: string,
        uiMinorColor: string,
        uiMajorTextColor: string,
        uiMinorTextColor: string,
        tpSuccessTitle: string,
        tpSuccessText: string,
        tpSuccessInstructionsText: string,
        tpSuccessInstructionsButton: string,
        tpShowShare: number,
        tpShowReceipt: number,
        tpShowInstructions: number,
        smsText: string,
        image: string,
        imageBody: string|null|ArrayBuffer = null
    ) => {
        return await this.api.addProduct(
            merchantId,
            type,
            name,
            description,
            totalPrice,
            basePrice,
            serviceFee,
            productPrice,
            taxValue,
            taxClass,
            deliveryUrl,
            isHidden,
            isPublished,
            showServiceFee,
            uiMajorColor,
            uiMinorColor,
            uiMajorTextColor,
            uiMinorTextColor,
            tpSuccessTitle,
            tpSuccessText,
            tpSuccessInstructionsText,
            tpSuccessInstructionsButton,
            tpShowShare,
            tpShowReceipt,
            tpShowInstructions,
            smsText,
            image,
            imageBody
        );
    };

    addProductCodes = async (id: number, codes: string[]): Promise<any> => {
        return await this.api.addProductCodes(id, codes);
    };

    genProductCodes = async (id: number, quantity: number, length: number, type: 'alpha'|'numeric'|'alphanumeric'): Promise<any> => {
            return await this.api.genProductCodes(id, quantity, length, type);
    };
}
