import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'mobx-react';
import MainStore from './store/mainStore';
import config from './config';
import './i18nConfig'; // init `i18next` so that it could be used later in the app
import {Auth0Provider} from './react-auth0-spa';

ReactDOM.render((
    <Auth0Provider
        domain={config.auth0.domain}
        client_id={config.auth0.clientId}
        audience={config.auth0.audience}
        redirect_uri={window.location.origin}
    >
        <Provider stores={new MainStore()}>
            <App />
        </Provider>
    </Auth0Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
