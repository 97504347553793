import './UploadArea.css';

import React, {useState} from 'react';

export const useUploadArea = (setUploadedCodes: Function) => {
    const [isDragging, setDragging] = useState(false);

    let dragCounter = 1;

    const preventDefaultBehavior = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const handleDragIn = (e: React.DragEvent) => {
        preventDefaultBehavior(e);

        dragCounter++;
        setDragging(true);
    };

    const handleDragOut = (e: React.DragEvent) => {
        preventDefaultBehavior(e);

        dragCounter--;
        if (dragCounter > 0) return;
        setDragging(false);
    };

    const handleDrag = (e: React.DragEvent) => {
        preventDefaultBehavior(e);
    };

    const handleDrop = (e: React.DragEvent) => {
        preventDefaultBehavior(e);

        if (e.dataTransfer.files[0].type === 'application/vnd.ms-excel') {
            handleFile(e.dataTransfer.files[0]);
            document.getElementsByClassName('codes-textarea')[0].classList.remove('drop-error');
        } else {
            document.getElementsByClassName('codes-textarea')[0].classList.add('drop-error');
        }

        e.dataTransfer.clearData();
        setDragging(false);
    };

    const handleFile = (file: File) => {
        const reader = new FileReader();

        reader.readAsText(file);

        reader.onload = async () => {
            setUploadedCodes(reader.result!)
        };
    }

    return {
        handleDragIn,
        handleDragOut,
        handleDrag,
        handleDrop,
        isDragging
    }
};

interface UploadAreaProps {
    codes: string;
    setCodes: Function;
    isDragging: boolean;
    onUploadClick: Function;
}

const UploadArea = ({ codes, setCodes, isDragging, onUploadClick }: UploadAreaProps) => {
    return (
        <>
            <textarea
                value={codes}
                className="codes-textarea"
                onChange={(e) => setCodes(e.currentTarget.value)}
                placeholder={'Copy-past codes or drag-and-drop .csv file'}
            />

            {isDragging &&
                <div className="drop-box" id="drop-box">
                    <span>DROP HERE</span>
                </div>
            }

            <button className='btn btn-upload' onClick={() => onUploadClick()}>Upload codes</button>
        </>
    );
};

export default UploadArea;
