import { getI18n } from 'react-i18next';
import moment from 'moment';
import {action, observable} from 'mobx';

export default class UiStore {
    @observable lang: string;

    @observable vehicleUrl: string = '/vehicle';
    @observable routeUrl: string = '/route';
    @observable reportUrl: string = '/report';

    constructor() {
        this.lang = getI18n().language;
        moment.locale(this.lang);
    }

    @action setLang(lang: string) {
        getI18n().changeLanguage(lang);
        moment.locale(lang);

        this.lang = lang;
    }
}
