import './ProductEditModals.css';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ProductModel from '../../models/ProductModel';
import SwitcherCheckbox from '../../components/SwitcherCheckbox';

interface PriceProps {
    product: ProductModel;
    onSave: Function;
    onClose: Function;
}

export const usePriceModal = (product: ProductModel) => {

    const [isPriceModalShowed, setIsPriceModalShowed] = useState(false);

    const showPriceModal = () => {
        setIsPriceModalShowed(true);
    };
    const closePriceModal = () => {
        setIsPriceModalShowed(false);
    };
    const savePrice = (price: number, taxClass: number) => {
        product.taxClass = taxClass;

        product.setPrices(price);

        setIsPriceModalShowed(false);
    };

    return {
        isPriceModalShowed,
        showPriceModal,
        closePriceModal,
        savePrice
    };
};

const PriceModal = ( { product, onSave, onClose }: PriceProps ) => {
    const { t } = useTranslation();

    const [taxClass, setTaxClass] = useState(product.taxClass);
    const [price, setPrice] = useState(product.totalPrice);

    const preparePriceValue = (value) => {
        value = value.replace(',', '.'); // replace all dots by commas
        value = value.replace(/[^0-9\.]/g, ''); // strip all characters except numbers and commas
        value = value.replace(/\.+/, '.');
        const matches = value.match(/\d+\.\d+/g); // find all matches for decimal numbers
        return matches ? matches[0] : value;
    };

    const vatSwitchers = ProductModel.VAT_CLASSES.map((tax, i) => {
        return (
            <label className="vat-switch" key={i}>{tax}%
                <input
                    type="radio"
                    name="vat-rates"
                    value={i}
                    checked={taxClass === i}
                    onChange={()=>setTaxClass(i)}
                />
                <span className="vat-switch-name" />
            </label>
        );
    });

    return (
        <div className="price-modal">
            <label className="price-modal-label">
                {t("priceModal.price")}
                <input
                    type="text"
                    value={price}
                    onChange={(e) => setPrice(preparePriceValue(e.target.value))}
                    className="price-input"
                />
                &euro;
            </label>

            <p>{t("priceModal.vatTitle")}</p>
            <div className="price-modal-vats">
                {vatSwitchers}
            </div>

            <div className="price-modal-check-fee" >
                <span>{t("priceModal.text")}</span>

                <SwitcherCheckbox prodProp={'showServiceFee'} style={{margin: "0"}} />
            </div>

            <p className="price-modal-fee-text">Hinta pitää sisällään mobiilimaksulisän.</p>

            <div className="d-flex justify-content-between">
                <button className="btn btn-success price-modal-btn" onClick={() => onSave(+price, taxClass)}>{t("button.save")}</button>
                <button className="btn btn-dark price-modal-btn" onClick={() => onClose()}>{t("button.close")}</button>
            </div>
        </div>
    );
};

export default PriceModal;
