import './UploadResultArea.css';
import '../UploadArea/UploadArea.css';

import React from 'react';
import { Link } from 'react-router-dom';

interface UploadResultAreaProps {
    goodCodes: string;
    badCodes: string;
    id: number;
    onNewUpload: Function;
}

const UploadResultArea = ({ goodCodes, badCodes, id, onNewUpload }: UploadResultAreaProps) => {
    return (
        <>
            <div className="upload-result-page">
                {goodCodes &&
                    <div className={badCodes ? 'upload-result-area_both' : 'upload-result-area'}>
                        <h3 className="upload-result-title upload-result-title_success">Codes have added successfully</h3>
                        <textarea
                            value={goodCodes}
                            className="codes-textarea codes-textarea_success"
                            readOnly={true}
                        />
                    </div>
                 }

                {badCodes &&
                    <div className={goodCodes ? 'upload-result-area_both' : 'upload-result-area'}>
                        <h3 className="upload-result-title upload-result-title_error">Codes have already used</h3>
                        <textarea
                            value={badCodes}
                            className="codes-textarea codes-textarea_error"
                            readOnly={true}
                        />
                    </div>
                }
            </div>
            <div className={'upload-result-buttons'}>
                <button className={'btn btn-dark upload-result-link'} onClick={() => onNewUpload()}>Upload new codes</button>
                <Link to={'/'} className={'btn btn-success upload-result-link'}>Back to products page</Link>
            </div>
        </>
    );
}

export default UploadResultArea;