import './ProductEditModals.css';

import React, { useState, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import Cropper from 'react-easy-crop';
import { Area, MediaSize, Point } from 'react-easy-crop/types';
import Slider from '@material-ui/core/Slider';

import ProductModel from '../../models/ProductModel';
import { getCroppedImg, setCompressedImage } from '../../services/imageService';

interface CroppedAreaPixels {
    width: number;
    height: number;
    x: number;
    y: number;
}

interface ImageProps {
    image: string,
    crop: { x: number, y: number },
    zoom: number,
    minZoom: number,
    cropSize: {
        width: number,
        height: number
    },
    croppedAreaPixels: null|CroppedAreaPixels
}

interface ImageCropModalProps {
    imageProps: ImageProps;
    onCropChange: (location: Point) => void;
    onCropComplete: (croppedArea: Area, croppedAreaPixels: Area) => void;
    onZoomChange: (zoom: number|number[]) => void;
    setImageZoom: (mediaSize: MediaSize) => void;
    onSave: Function;
    onCancel: Function;
}

export const useImageCropper = (product: ProductModel) => {
    const [isDraggable, setIsDraggable] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const [imageProps, setImageProps] = useReducer(
        (imageProps, newImageProps) => ({...imageProps, ...newImageProps}),
        {
            image: null,
            crop: { x: 0, y: 0 },
            zoom: 1,
            minZoom: 1,
            cropSize: {
                width: 412,
                height: 412
            },
            croppedAreaPixels: null
        }
    );

    const onCropChange = crop => {
        setImageProps({ crop });
    };

    const onCropComplete = async (croppedArea, croppedAreaPixels) => {
        setImageProps({ croppedAreaPixels });
    };

    const onZoomChange = zoom => {
        setImageProps({ zoom });
    };

    const setImageZoom = ({ width, height }) => {
        const minSide = Math.min(width, height);
        if (414/minSide > 1) {
            const zoom = Math.ceil(414/minSide*100)/100;
            setImageProps({
                zoom,
                minZoom: zoom
            });
        } else {
            const zoom = Math.ceil(minSide/414*100)/100;
            setImageProps({
                zoom: 1,
                minZoom: 1/zoom
            });
        }
    };

    const processImage = () => {
        setIsProcessing(true);
        setTimeout(async() => {
            try {
                await setNewImage();
                setIsProcessing(false);
                setIsDraggable(false);
            } catch (e) {
                console.log('Image saving failed: ', e);
                setIsProcessing(false);
                setIsDraggable(false);
            }
        }, 100);
    };

    const setNewImage = async () => {
            const croppedImage: string = await getCroppedImg(
                imageProps.image,
                imageProps.croppedAreaPixels
            );
            await setCompressedImage(croppedImage, product);

            product.image = croppedImage;

            setImageProps({
                image: null
            });
    };

    const loadImage = async (e) => {
        const file = e.target.files[0];

        const reader = new FileReader();

        if (file && file.type.match('image.*')) {
            product.imageName = file.name;
            reader.readAsDataURL(file);
        }

        reader.onload = async () => {
            setImageProps({
                image: reader.result
            });
            setIsDraggable(true);
        };
    };

    const closeImageModal = () => {
        setIsDraggable(false);
    };

    return {
        isDraggable,
        imageProps,
        onCropChange,
        onCropComplete,
        onZoomChange,
        setImageZoom,
        processImage,
        loadImage,
        isProcessing,
        closeImageModal
    };
};

const ImageCropperModal = ({ imageProps, onCropChange, onCropComplete, onZoomChange, setImageZoom, onSave, onCancel }: ImageCropModalProps) => {
    const { t } = useTranslation();

    const { image, crop, zoom, minZoom, cropSize } = imageProps;

    return (
        <div className="img-cropper-wrap">
            <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                minZoom={minZoom}
                maxZoom={minZoom + 3}
                cropSize={cropSize}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
                onMediaLoaded={setImageZoom}
                zoomWithScroll={false}
                style={{
                    containerStyle: {borderRadius: "2.6rem", height: "auto", top: "11%"},
                    mediaStyle: {width: "auto", borderRadius: "2.6rem"},
                    cropAreaStyle: { borderRadius: "2.6rem"}
                }}
            />

            <div className="slider-wrap">
                <Slider
                    orientation="vertical"
                    value={zoom}
                    min={minZoom}
                    max={minZoom + 3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => onZoomChange(zoom)}
                    classes={{ root: 'slider' }}
                />
            </div>

            <button type="button" className="btn btn-success btn-image btn-image-save" onClick={() => onSave()}>{t("button.save")}</button>
            <button type="button" className="btn btn-dark btn-image btn-image-cancel" onClick={() => onCancel()}>{t("button.cancel")}</button>
        </div>
    );
};

export default ImageCropperModal;