import './TicketPage.css';

import React, { useEffect } from 'react';
import { Editor, EditorState, convertFromHTML, ContentState } from 'draft-js';
import { inject, observer } from 'mobx-react';

import MainStore from '../../store/mainStore';
import Receipt from '../../components/Receipt';

interface TicketPageProps {
    stores?: MainStore,
}

const TicketPage = ({ stores }: TicketPageProps) => {
    const { productStore } = stores!;
    const { merchant } = stores!.merchantStore;
    const product = productStore.product;

    const [editorState, setEditorState] = React.useState(
        () => EditorState.createEmpty()
    );

    // watch for changes in product description and set new Editor state
    useEffect((): void|(() => void) => {
        const HTMLDescription = convertFromHTML(product.description);
        const editedTextState = ContentState.createFromBlockArray(
            HTMLDescription.contentBlocks,
            HTMLDescription.entityMap,
        );
        setEditorState(() => EditorState.createWithContent(editedTextState));
    }, [product.description]);

    return (
        <div className="lift-body">
            <div className="sm-lift">
                <div className="sm-floor sm-floor-flex" id="ticket">
                    <div className="sm-order-head">
                        <div className="sm-order-logo" style={{backgroundImage: `url(${merchant.logo})`}} />
                    </div>

                    <div className="sm-order-ticket">
                        <div className="sm-order-ticket-part">
                            <div className="sm-order-ticket-inner">
                                <div className="sm-order-ticket-bg"
                                     style={{backgroundImage: `url(${product.image})`}} />
                                <div className="sm-order-ticket-content">
                                    <div className="sm-order-ticket-datetime">
                                        <span>4da82bed-7f4a-11ea-8f46-000d3ab9eb7e</span>
                                        <span>24.4.2020 10:07</span>
                                    </div>

                                    <div className="sm-order-ticket-title">Ticket</div>

                                    <div className="sm-order-ticket-product">
                                        <span>{product.name}</span>
                                        <b>{product.totalPrice}&euro;</b>
                                    </div>

                                    <div className="sm-order-ticket-datetime">
                                        <span>4da82bed-7f4a-11ea-8f46-000d3ab9eb7e</span>
                                        <span>24.4.2020 10:07</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="sm-order-ticket-separator"></div>

                        <div className="sm-order-ticket-part sm-order-ticket-part-control">
                            <div className="sm-order-ticket-inner">
                                <img className="sm-order-ticket-qr" src="http://qrcoder.ru/code/?4da82bed-7f4a-11ea-8f46-000d3ab9eb7e&4&0" title="QR code" />
                            </div>
                        </div>
                    </div>

                    <div className="sm-next-wrap">
                        <a className="sm-next" href="#description" />
                    </div>
                </div>

                <div className="sm-floor" id="description">
                    <div className="sm-product-panel">
                        <div className="sm-product-description description_ticket">
                            <Editor editorState={editorState} />
                        </div>

                        <div>
                            <a className="sm-next" href="#receipt" />
                        </div>
                    </div>
                </div>

                <div className="sm-floor sm-floor-flex" id="receipt">
                    <Receipt product={product} merchant={merchant} />

                    <div>
                        <a className="sm-next" href="#blank" />
                    </div>
                </div>

                <div className="sm-floor sm-blank-floor" id="blank">
                    <div className="sm-product-panel" />
                </div>

                <div className="sm-floor sm-floor-caution" id="caution">
                    <div className="sm-product-panel">
                        <div className="sm-caution-title"><b>AINOASTAAN</b> Henkilökunnan käyttöön</div>

                        <div className="sm-caution-text">Tämä osio on tarkoitettu lipun deaktivointiin. Painettuasi
                            lippuikonit roskakoriin, lippua ei voi enää käyttää.
                        </div>

                        <span className="sm-deactivator">
                            <span className="sm-deactivator-text">TUHOA LIPPU &gt;&gt;</span>
                            <span className="sm-deactivator-slider" id="deactivationButton" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default inject('stores')(observer(TicketPage));