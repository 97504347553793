import './ProductCard.css';

import React from 'react';
import ProductModel from '../../models/ProductModel';

interface ProductCardProps {
    product: IProduct;
    getDataAndNavigateToProduct: Function;
    navigateToCodes: Function;
}

const ProductCard = ({ product, getDataAndNavigateToProduct, navigateToCodes }: ProductCardProps) => {

    const { name, description, image, id, isHidden, isPublished, totalPrice, type  } = product;

    let productTypeClasses = 'product-type';

    if (isPublished) {
        productTypeClasses += ' product-type_published';
    }

    if (isHidden) {
        productTypeClasses += ' product-type_hidden';
    }

    const createDescriptionMarkup = () => {
        return {__html: description}
    };

    return (
        <section className="product-card" id={`${id}`}>
            <div className={productTypeClasses} />
            <div className="product-info">
                <div>
                    <h3 className="product-title">{name}</h3>
                    <span className="product-price">{totalPrice} &euro;</span>
                    <div className="product-description" dangerouslySetInnerHTML={createDescriptionMarkup()} />
                </div>

                <div>
                    <button className="product-btn product-edit" onClick={() => getDataAndNavigateToProduct(id)}>Edit</button>
                    {type === 'coupon' &&
                        <button className="product-btn product-codes" onClick={() => navigateToCodes(id)}>Codes</button>
                    }
                </div>
            </div>
            <div className="product-img" style={{background: `url('${image}') 0 0 / 100% no-repeat`}} />
        </section>
    );
};

export default ProductCard;