import config from '../config';

interface ColorScheme {
    uiMajorColor: string;
    uiMinorColor: string;
    uiMajorTextColor: string;
    uiMinorTextColor: string;
}

export const setColor = (name: string, value: string) => {
    document.documentElement.style.setProperty(
        name,
        value
    );
};

export const getColor = (name: string): string => {
    return getComputedStyle(document.documentElement)
        .getPropertyValue(name);
};

export const setColorScheme = (colorScheme: ColorScheme) => {
    setColor('--major-color', colorScheme.uiMajorColor);
    setColor('--minor-color', colorScheme.uiMinorColor);
    setColor('--major-text-color', colorScheme.uiMajorTextColor);
    setColor('--minor-text-color', colorScheme.uiMinorTextColor);
};

export const formatToStringView = (price: number): string => {
    let newPrice = price.toFixed(2).replace('.', ',');
    return newPrice.slice(-2) === '00' ? newPrice.slice(0,-3) : newPrice;
};

export const prepareColorForSend = (color: string): string => {
    return color.slice(1);
};

export const setIconUrlVariables = () => {
    document.documentElement.style.setProperty('--i-share-url', `url(${config.site.url}/i/share.png)`);
    document.documentElement.style.setProperty('--i-receipt-url', `url(${config.site.url}/i/receipt.png)`);
    document.documentElement.style.setProperty('--i-use-url', `url(${config.site.url}/i/use.png)`);
    document.documentElement.style.setProperty('--i-copy-url', `url(${config.site.url}/i/copy.png)`);
    document.documentElement.style.setProperty('--i-link-url', `url(${config.site.url}/i/link.png)`);
    document.documentElement.style.setProperty('--i-exit-url', `url(${config.site.url}/i/exit.png)`);
    document.documentElement.style.setProperty('--i-capture-url', `url(${config.site.url}/i/capture.png)`);
    document.documentElement.style.setProperty('--i-popup_close-url', `url(${config.site.url}/i/popup_close.png)`);
    document.documentElement.style.setProperty('--i-fail-url', `url(${config.site.url}/i/fail.png)`);
    document.documentElement.style.setProperty('--i-logo-mm-url', `url(${config.site.url}/i/mobiilimaksu-logo.png)`);
    document.documentElement.style.setProperty('--i-logo-elisa-url', `url(${config.site.url}/i/elisa-logo.png)`);
    document.documentElement.style.setProperty('--i-logo-telia-url', `url(${config.site.url}/i/telia-logo.png)`);
    document.documentElement.style.setProperty('--i-logo-moi-url', `url(${config.site.url}/i/moi-logo.png`);
    document.documentElement.style.setProperty('--i-logo-dna-url', `url(${config.site.url}/i/dna-logo.png`);
    document.documentElement.style.setProperty('--i-achtung-url', `url(${config.site.url}/i/achtung.png`);
    document.documentElement.style.setProperty('--i-back-url', `url(${config.site.url}/i/back.png`);
    document.documentElement.style.setProperty('--i-validator-url', `url(${config.site.url}/i/icon-validator.png`);
    document.documentElement.style.setProperty('--i-tickets-url', `url(${config.site.url}/i/icon-tickets.png`);
};