import '../SwitcherCheckbox/SwitcherCheckbox.css';
import './SettingsSwitcherCheckbox.css';

import React, { useState } from 'react';

interface SwitcherComponentProps {
    isEnable: boolean
}

const SettingsSwitcherCheckbox = ({ isEnable }: SwitcherComponentProps ) => {

    let initialStatus = isEnable === true ? 'enabled' : 'disabled';
    const [switcherStatus, setSwitcherStatus] = useState(initialStatus);

    const changeSwitcherStatus = () => {
        setSwitcherStatus(
            switcherStatus === 'enabled' ? 'disabled' : 'enabled'
        );
    };

    return (
        <div className="switcher-wrapper">
            <label className="switcher">
                <input type="checkbox" onClick={changeSwitcherStatus}/>
                <div className="switcher-btn" />
            </label>

            <span className="switcher-status">{switcherStatus}</span>
        </div>
    );
};

export default SettingsSwitcherCheckbox;
