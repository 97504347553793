import './Header.css';

import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {inject, observer} from 'mobx-react';
import MainStore from '../../store/mainStore';
import {Link} from 'react-router-dom';
import {useAuth0} from '../../react-auth0-spa';
import {NavDropdown} from 'react-bootstrap';
import {version as appVersion} from '../../../package.json';

type ThisComponentProps = {
    stores?: MainStore,
}

const langs = [{id: 'fi', name: 'Suomi'}, {id: 'en', name: 'English'}];

const Header = ({stores} : ThisComponentProps) => {
    const { t } = useTranslation();
    const { loading, isAuthenticated, loginWithPopup, logout} = useAuth0();

    const changeLanguage = (id) => stores!.uiStore.setLang(id);

    const merchantStore = stores!.merchantStore;

    const [logo, setLogo] = useState(merchantStore.merchant.logo);
    const [logoUpdatable, setLogoUpdatable] = useState(merchantStore.merchant.id !== null);

    useEffect(() => setLogo(merchantStore.merchant.logo), [merchantStore.merchant.logo]);
    useEffect(() => setLogoUpdatable(merchantStore.merchant.id !== null), [merchantStore.merchant.id]);

    const loadImage = (e) => {
        const file = e.target.files[0];

        if (!file || !file.type.match('image.png')) {
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = async () => {
            const merchant = await merchantStore.updateLogo(merchantStore.merchant.id!, file.name, reader.result);
            if (merchant) {
                merchantStore.setMerchant(merchant);
            }
        };
    };

    return (
        <nav className="navbar d-flex justify-content-between">
            <div className="d-flex align-items-center navbar-logo-wrap">
                <div className={`navbar-logo-logo ${logoUpdatable ? 'navbar-logo-updatable' : ''}`}
                     style={{'backgroundImage': logo ? `url(${logo})` : '/android-chrome-192x192.png'}}>
                    {logoUpdatable && <input type="file"
                         accept="image/png"
                         className="navbar-logo-updater"
                         onChange={loadImage}/>
                    }
                </div>
                <Link to='/' className="navbar-logo">
                    SHOP
                </Link>
            </div>

            <NavDropdown
                className=""
                title={<span className="navbar-company">Change language</span>}
                id="basic-nav-dropdown">

                {langs.map(i => <NavDropdown.Item key={i.id} onClick={() => changeLanguage(i.id)}>{i.name}</NavDropdown.Item>)}
            </NavDropdown>

            <div className="d-flex align-items-center">
                <div>
                    {!loading && !isAuthenticated && (
                        <button className={'btn btn-outline-primary btn-auth'} onClick={() => loginWithPopup!({})}>{t('button.login')}</button>
                    )}
                    {isAuthenticated && (
                        <button className={'btn btn-outline-primary btn-auth'} onClick={() => logout!({})}>{t('button.logout')}</button>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default inject('stores')(observer(Header));
