import './ProductEditModals.css';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ProductModel from '../../models/ProductModel';

interface PriceProps {
    currentTaxClass: number;
    onSave: Function;
    onClose: Function;
}

export const useVatModal = (product: ProductModel) => {
    const [isVatModalShowed, setIsVatModalShowed] = useState(false);

    const showVatModal = () => {
        setIsVatModalShowed(true);
    };

    const saveVatClass = (taxClass) => {
        product.taxClass = taxClass;

        product.setPrices(product.totalPrice);

        setIsVatModalShowed(false);
    };

    const closeVatModal = (curTaxClass) => {
        product.taxClass = curTaxClass;
        setIsVatModalShowed(false);
    };

    return {
        isVatModalShowed,
        showVatModal,
        saveVatClass,
        closeVatModal
    };
};

const VatClassesModal = ( { currentTaxClass, onSave, onClose }: PriceProps ) => {
    const { t } = useTranslation();

    const [taxClass, setTaxClass] = useState(currentTaxClass);

    const vatSwitchers = ProductModel.VAT_CLASSES.map((tax, i) => {
        return (
            <label className="vat-switch" key={i}>{tax}%
                <input
                    type="radio"
                    name="vat-rates"
                    value={i}
                    checked={taxClass === i}
                    onChange={()=>{setTaxClass(i)}}
                />
                <span className="vat-switch-name" />
            </label>
        );
    });

    return (
        <div className="vat-classes-modal">

            <p>Choose product vat rate</p>
            <div className="price-modal-vats">
                {vatSwitchers}
            </div>

            <div className="d-flex justify-content-between">
                <button className="btn btn-success price-modal-btn" onClick={() => onSave(taxClass)}>{t("button.save")}</button>
                <button className="btn btn-dark price-modal-btn" onClick={() => onClose(currentTaxClass)}>{t("button.close")}</button>
            </div>
        </div>
    );
};

export default VatClassesModal;
