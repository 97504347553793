import './ProductEditModals.css';

import React, {useReducer, useState} from 'react';
import { useTranslation } from 'react-i18next';

import ProductModel from '../../models/ProductModel';
import ProductStore from '../../store/productStore';
import { setColorScheme, setColor } from '../../services/productService';

const colorSchemes = [
    {uiMajorColor: '#170525', uiMinorColor: '#9F2B93', uiMajorTextColor: '#FFFFFF', uiMinorTextColor: '#FFFFFF'},
    {uiMajorColor: '#C21623', uiMinorColor: '#F9959C', uiMajorTextColor: '#FFFFFF', uiMinorTextColor: '#FFFFFF'},
    {uiMajorColor: '#0E7B6F', uiMinorColor: '#004E46', uiMajorTextColor: '#FFFFFF', uiMinorTextColor: '#FFFFFF'},
    {uiMajorColor: '#79B700', uiMinorColor: '#33691E', uiMajorTextColor: '#FFFFFF', uiMinorTextColor: '#FFFFFF'},
    {uiMajorColor: '#E64A19', uiMinorColor: '#AC0800', uiMajorTextColor: '#FFFFFF', uiMinorTextColor: '#FFFFFF'},
    {uiMajorColor: '#FBE1E3', uiMinorColor: '#9E2D5A', uiMajorTextColor: '#9E2D5A', uiMinorTextColor: '#FFFFFF'},
    {uiMajorColor: '#E7F0F3', uiMinorColor: '#1C4A6F', uiMajorTextColor: '#1C4A6F', uiMinorTextColor: '#FFFFFF'},
    {uiMajorColor: '#FFF59D', uiMinorColor: '#BF360C', uiMajorTextColor: '#BF360C', uiMinorTextColor: '#FFFFFF'},
    {uiMajorColor: '#D7CCC8', uiMinorColor: '#6D4C41', uiMajorTextColor: '#6D4C41', uiMinorTextColor: '#FFFFFF'},
    {uiMajorColor: '#DCEDC8', uiMinorColor: '#558B2F', uiMajorTextColor: '#558B2F', uiMinorTextColor: '#FFFFFF'},
];

export const useColorSchemeModal = (product: ProductModel, productStore: ProductStore) => {
    const { t } = useTranslation();

    const [isColorPickerShowed, setIsColorPickerShowed] = useState(false);
    const inputsData = [
        {label: t("colorModal.majorColor"), colorVar: '--major-color', usedColor: 'majorColor'},
        {label: t("colorModal.minorColor"), colorVar: '--minor-color', usedColor: 'minorColor'},
        {label: t("colorModal.majorTextColor"), colorVar: '--major-text-color', usedColor: 'majorTextColor'},
        {label: t("colorModal.minorTextColor"), colorVar: '--minor-text-color', usedColor: 'minorTextColor'}
    ];

    const initialColorState = {
        majorColor: product.uiMajorColor,
        minorColor: product.uiMinorColor,
        majorTextColor: product.uiMajorTextColor,
        minorTextColor: product.uiMinorTextColor
    };

    const [usedColors, setUsedColors] = useReducer(
        (usedColors, newUsedColors) => ({...usedColors, ...newUsedColors}),
        initialColorState
    );

    const setUsedScheme = (scheme) => {
        setUsedColors({
            majorColor: scheme.uiMajorColor,
            minorColor: scheme.uiMinorColor,
            majorTextColor: scheme.uiMajorTextColor,
            minorTextColor: scheme.uiMinorTextColor,
        });
    };

    const colorInputs = inputsData.map((data, i) => {
        return (
            <label className="color-picker-label" key={i}>
                {data.label}
                <input
                    value={usedColors[data.usedColor]}
                    className="color-picker-int"
                    type={'text'}
                    maxLength={7}
                    onChange={(e) => {
                        setUsedColors({
                            [data.usedColor]: e.target.value
                        });
                        validateColor(e.target.value) && setColor(data.colorVar, e.target.value);
                    }}
                />
            </label>
        );
    });

    const validateColor = (color) => {
        return /^#[0-9A-F]{6}$/i.test(color);
    };

    const showColorPicker = () => {
        setIsColorPickerShowed(true);
    };

    const closeColorPicker = () => {
        setColorScheme(product);

        setIsColorPickerShowed(false);
    };

    const saveChosenColor = () => {
        productStore.saveProductColorScheme();

        setIsColorPickerShowed(false);
    };

    return {
        isColorPickerShowed,
        showColorPicker,
        closeColorPicker,
        saveChosenColor,
        setUsedScheme,
        colorInputs
    }
};

interface ColorSchemeProps {
    onSave: Function;
    onClose: Function;
    setUsedScheme: Function;
    colorInputs: any;
}

const ColorSchemeModal = ({onSave, onClose, setUsedScheme, colorInputs }: ColorSchemeProps) => {
    const { t } = useTranslation();

    const colorSchemeList = colorSchemes.map((colorScheme, i) => {
        return (
            <div
                key={i}
                onClick={() => {
                    setColorScheme(colorScheme);
                    setUsedScheme(colorScheme);
                }}
                className="color-scheme"
                style={{width: "40px", height: "40px", background: colorScheme.uiMajorColor, marginBottom: "10px", padding: "12px"}}
            >
                <div style={{background: colorScheme.uiMinorColor, width: "100%", height: "100%"}} />
            </div>
        );
    });

    return (
        <div className="color-picker">
            <h4 className="color-picker-title">{t("colorModal.chooseColors")}</h4>
            <div className="color-picker-schemes">
                {colorSchemeList}
            </div>

            <h4 className="color-picker-title">{t("colorModal.inputColors")}</h4>
            <div className="color-picker-inputs">
                {colorInputs}
            </div>

            <div className="color-picker-buttons">
                <button
                    className="btn btn-success modal-btn"
                    onClick={() => onSave()}
                >{t("button.save")}</button>
                <button
                    className="btn btn-dark modal-btn"
                    onClick={() => onClose()}
                >{t("button.close")}</button>
            </div>
        </div>
    );
};

export default ColorSchemeModal;
