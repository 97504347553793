import './CouponPage.css';

import React from 'react';
import { inject, observer } from 'mobx-react';
import TextareaAutosize from 'react-autosize-textarea';

import MainStore from '../../store/mainStore';
import SwitcherCheckbox from '../../components/SwitcherCheckbox';
import Receipt from '../../components/Receipt';
import HTMLAndTextEditor from '../../components/HTMLAndTextEditor';

interface CouponPageProps {
    stores?: MainStore,
}

const CouponPage = ({ stores }: CouponPageProps) => {
    const { productStore } = stores!;
    const { merchant } = stores!.merchantStore;
    const product = productStore.product;

    return (
            <div className="sm-lift">
                <div className="sm-floor sm-floor-flex" id="landing">
                    <div className="sm-order-head">
                        <div className="sm-order-logo" style={{backgroundImage: `url(${merchant.logo})`}} />
                    </div>

                    <div>
                        <TextareaAutosize
                            defaultValue={product.tpSuccessTitle}
                            onChange={(e) => {product.tpSuccessTitle = e.currentTarget.value}}
                            className="sm-order-title sm-product-title_editable sm-product-text_editable"
                        />

                        <TextareaAutosize
                            defaultValue={product.tpSuccessText}
                            onChange={(e) => {product.tpSuccessText = e.currentTarget.value}}
                            className="sm-order-text sm-product-text_editable sm-order-text_editable"
                        />
                    </div>

                    <div>
                        <a className="sm-button" href="#code">
                            <span className="sm-next" /> JATKA
                        </a>
                    </div>
                </div>

                <div className="sm-floor sm-floor-flex" id="code">
                    <div>
                        <h2 className="sm-order-subtitle">Kopioi Katselukoodisi</h2>

                        <div className="sm-order-text">painamalla alla olevaa näppäintä</div>
                    </div>

                    <div className="sm-order-inner">
                        <div className="sm-order-code">coupon code</div>

                        <div className="sm-order-buttons">
                            <button type="button" id="copy_btn" className="sm-button sm-button-action sm-button-copy">Kopioi</button>
                        </div>
                    </div>


                    <div>
                        <div className="sm-next-text">Jatka eteenpäin</div>
                        <a className="sm-next" href="#next" />
                    </div>

                </div>

                <div className="sm-floor sm-floor-flex" id="next">
                    <div>
                        <h2 className="sm-order-subtitle">Mitä haluat seuraavaksi?</h2>

                        <div className="sm-order-text">Valitse alla olevista kuvakkeista</div>
                    </div>

                    <div className="sm-order-inner">
                        <div className="option-switcher">
                            <a className="sm-order-option sm-order-option-share" href="#share">
                                <span>Jaa kaverille</span>
                            </a>

                            <SwitcherCheckbox prodProp={'tpShowShare'} />
                        </div>

                        <div className="option-switcher">
                            <a className="sm-order-option sm-order-option-white sm-order-option-receipt" href="#receipt">
                                <span>Kuitti</span>
                            </a>

                            <SwitcherCheckbox prodProp={'tpShowReceipt'} />
                        </div>

                        <div className="option-switcher">
                            <a className="sm-order-option sm-order-option-use" href="#use">
                                <span>Käytän koodin</span>
                            </a>

                            <SwitcherCheckbox prodProp={'tpShowInstructions'} />
                        </div>
                    </div>

                    <div>
                        <a className="sm-next" href="#share" />
                    </div>
                </div>

                <div className="sm-floor sm-floor-flex" id="share">
                    <div>
                        <h2 className="sm-order-subtitle">Jaa kaverille?</h2>

                        <div className="sm-order-text">Ilahduta kaveriasi jakamalla koodi kaverillesi.</div>
                    </div>

                    <div className="sm-order-inner">
                        <div className="sm-order-share-text">Koodi on käytössä vain kerran</div>

                        <div className="sm-order-buttons">
                            <button disabled type="button" id="share_btn" className="sm-button sm-button-action sm-button-share">Jaa</button>
                        </div>
                    </div>

                    <div>
                        <a className="sm-next" href="#receipt" />
                    </div>
                </div>

                <div className="sm-floor sm-floor-flex" id="receipt">
                    <Receipt product={product} merchant={merchant} />

                    <div>
                        <a className="sm-next" href="#use" />
                    </div>
                </div>

                <div className="sm-floor sm-floor-flex" id="use">
                    <div>
                        <h2 className="sm-order-title">Siirry katsomaan</h2>
                    </div>

                    <div className="sm-order-text sm-order-inner">
                        <HTMLAndTextEditor
                            product={product}
                            editedProductField={'tpSuccessInstructionsText'}
                        />
                    </div>

                    <div className="sm-button-wrap">
                        <TextareaAutosize
                            defaultValue={product.tpSuccessInstructionsButton}
                            onChange={(e) => {product.tpSuccessInstructionsButton = e.currentTarget.value}}
                            className="sm-button sm-button-action sm-button-use button_editable"
                            style={{width: "250px"}}
                        />
                    </div>
                </div>
            </div>
    );
};

export default inject('stores')(observer(CouponPage));