import './ProductsPage.css'

import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import { WithTranslation, withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import MainStore from '../../store/mainStore';

import ProductCard from '../../components/ProductCard';
import Spinner from '../../components/Spinner';
import { setColorScheme } from '../../services/productService';

interface ThisComponentProps extends RouteComponentProps<any>, WithTranslation {
    stores: MainStore
}

const ProductsPage = ({ stores, history, location }: ThisComponentProps) => {
    const { productStore, merchantStore } = stores;

    const [products, setProducts] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const { merchant } = merchantStore;

    const scrollToCard = () => {
        if (location.state) {
            const id = location.state['id'];
            const card = document.getElementById(id);
            card!.scrollIntoView({block: 'center'});
        }
    };

    useEffect(() => {
        merchant.id && productStore.getProducts()
            .then(products => {
                setProducts(products);
                setIsDataLoading(false);
                scrollToCard();
            })
            .catch( () => {
                setIsDataLoading(false);
            });
    }, [productStore]);

    const renderProductsList = (products: IProduct[]) => {
        return products.map( (product) => {
            return <ProductCard
                    product={product}
                    key={product.id}
                    getDataAndNavigateToProduct={setProductAndNavigate}
                    navigateToCodes={navigateToCodes} />
        });
    };

    const setProductAndNavigate = async (id: number|'new') => {
        setIsDataLoading(true);

        try {
            id === 'new' ?
                await productStore.setDefaultProduct(merchant) :
                productStore.setCustomProduct(await productStore.getProduct(id));
            setColorScheme(productStore.product);

            localStorage.setItem('initialProduct', JSON.stringify(productStore.product));
            localStorage.setItem('editedProduct', JSON.stringify(productStore.product));
            localStorage.setItem('id', `${id}`);

            setIsDataLoading(false);

            history.push(`/products/${id}`);
        } catch (e) {
            setIsDataLoading(false);
        }
    };

    const navigateToCodes = (id: number) => {
        localStorage.setItem('productId', `${id}`);
        history.push(`/products/${id}/codes`);
    }

    return (
        <>
            {isDataLoading &&
                <Spinner />
            }

            <div className="main">
                <div className="main-header">
                    <h2 className="main-title">Getting Started</h2>
                    <button className="main-btn" onClick={() => setProductAndNavigate('new')}>+ Add products</button>
                </div>
                {products && renderProductsList(products)}
            </div>
        </>
    );
};

export default inject('stores')(observer(withTranslation()(ProductsPage)));
