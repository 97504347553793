import React, {Component, useEffect} from 'react';
import {Route, useHistory} from 'react-router-dom';
import {useAuth0} from '../../react-auth0-spa';
import {inject} from 'mobx-react';

const PrivateRoute = ({ component: Component, path, stores, ...rest }: any) => {
    const { isAuthenticated } = useAuth0();
    const history = useHistory();
    const { merchantStore } = stores!;

    useEffect(() => {
        async function initMerchant() {
            const merchant = await merchantStore.getMerchant();
            console.log(merchant);
            if (merchant) {
                merchantStore.setMerchant(merchant);
                console.log('redirect to products');
                history.push(path === '/register/' ? '/products/' : path);
            } else {
                console.log('redirect to registration');
                history.push('/register/');
            }
        }

        !merchantStore.merchant.id && isAuthenticated && initMerchant();
    }, [isAuthenticated, merchantStore.merchant.id]);

    if (!isAuthenticated || (!merchantStore.merchant.id && path != '/register/')) {
        return null;
    }

    return <Route path={path} component={Component} {...rest} />;
};

export default inject('stores')(PrivateRoute);
