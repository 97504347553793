import './ErrorModal.css';

import React from 'react';
interface ErrorModalProps {
    onClose: Function;
    message: string;
}

const ErrorModal = ({ onClose, message }: ErrorModalProps) => {
    return (
        <div className="sm-overlay">
            <div className="sm-popup">
                <button type="button" className="sm-popup-close" onClick={() => onClose()}/>

                <div className="sm-popup-icon sm-popup-icon-fail"/>

                <div className="sm-popup-title">Hups!</div>

                <div className="sm-popup-subtitle">{message ? message : 'Something has gone terribly wrong.'}</div>
            </div>
        </div>
    );
};

export default ErrorModal;