import './CodesPage.css';

import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';

import UploadCodesComponent from '../../components/UploadCodesComponents/UploadCodesComponent';
import GenCodesComponent from '../../components/GenCodesComponents/GenCodesComponent';

interface ThisRouteParams {
    id: string,
}

const CodesPage = ({ match, history }: RouteComponentProps<ThisRouteParams>) => {
    let { id } = match.params;

    useEffect(() => {
        id = localStorage.getItem('productId')!;
        history.push(`/products/${id}/codes`);
    }, [id]);

    const [showedPage, setShowedPage] = useState<string>('upload');

    return (
        <section className="codes">
            <div style={{backgroundColor: '#e7e6e6'}}>
                <button className="tab-btn tab-btn-upload"
                        style={{backgroundColor: `${showedPage === 'upload' ? '#efefef' : '#e7e6e6'}`}}
                        onClick={() => {
                            setShowedPage('upload');
                            console.log(showedPage);
                        }}
                >Upload</button>
                <button
                    className="tab-btn tab-btn-gen"
                    style={{backgroundColor: `${showedPage === 'generate' ? '#efefef' : '#e7e6e6'}`}}
                    onClick={() => {
                        setShowedPage('generate');
                    }}
                >Generate</button>
            </div>

            {showedPage === 'upload' &&
                <UploadCodesComponent id={+id}/>
            }

            {showedPage === 'generate' &&
                <GenCodesComponent id={+id}/>
            }
        </section>
    )
};

export default inject('stores')(observer(CodesPage));