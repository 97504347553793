import { action, observable } from 'mobx';
import api from './api';
import MerchantModel from '../models/MerchantModel';

export default class MerchantStore {
    private api: typeof api;
    @observable merchant: MerchantModel;

    constructor() {
        this.api = api;
        this.merchant = new MerchantModel();
    }

    @action
    getMerchant = async () => {
        return await this.api.getMerchant();
    };

    @action previewMerchantUrlName = async (name: string): Promise<string> => {
        return await this.api.previewMerchantUrlName(name);
    };

    @action
    registerMerchant = async (authId: string, name: string) => {
        return await this.api.registerMerchant(authId, name);
    };

    @action
    updateLogo = async (id: number, logo: string, logoBody: string|null|ArrayBuffer = null) => {
        return await this.api.setMerchantLogo(id, logo, logoBody);
    };

    @action setMerchant = (merchant: IMerchant) => {
        this.merchant.setData(merchant);
    };
}
