import './UploadCodesComponent.css';

import React, {  useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import MainStore from '../../../store/mainStore';

import UploadArea, { useUploadArea } from '../UploadArea';
import UploadResultArea from '../UploadResultArea';
import Spinner from '../../Spinner';

interface UploadResult {
    good: string[];
    bad: string[];
}

interface ThisComponentProps {
    stores?: MainStore,
    id: number
}

const UploadCodesComponent = ({ stores, id }: ThisComponentProps) => {
    const { productStore } = stores!;

    const [codes, setCodes] =useState<any>('');
    const [goodCodes, setGoodCodes] = useState('');
    const [badCodes, setBadCodes] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [codesLoaded, setCodesLoaded] = useState(false);

    let dndRef: any = React.useRef();

    const setUploadedCodes = (codes) => {
        setCodes(codes);
    }

    const {
        handleDragIn,
        handleDragOut,
        handleDrag,
        handleDrop,
        isDragging
    } = useUploadArea(setUploadedCodes);

    useEffect(() => {
        let dndArea = dndRef.current!;
        dndArea.addEventListener('dragenter', handleDragIn);
        dndArea.addEventListener('dragleave', handleDragOut);
        dndArea.addEventListener('dragover', handleDrag);
        dndArea.addEventListener('drop', handleDrop);
    });

    const addCodes = async () => {
        if (!codes) return;

        setIsLoading(true);

        try {
            const result = await productStore.addProductCodes(+id, formatCodesStringToArray(codes));

            handleUploadResult(result);

            setIsLoading(false);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUploadResult = (result: UploadResult) => {
        setGoodCodes(formatCodesArrayToString(result.good));
        setBadCodes(formatCodesArrayToString(result.bad));

        setCodesLoaded(true);
    };

    const formatCodesStringToArray = (codesString: string) => {
        return codesString.split('\n');
    };

    const formatCodesArrayToString = (codesArray: string[]) => {
        return codesArray.join('\n');
    };

    const resetCodes = () => {
        setCodes('');
        setCodesLoaded(false);
    }

    return (
        <div className="upload-page" ref={dndRef} id="upload-page">
            {isLoading &&
                <Spinner />
            }

            {!codesLoaded &&
                <UploadArea isDragging={isDragging} codes={codes} setCodes={setUploadedCodes} onUploadClick={addCodes}/>
            }

            {codesLoaded &&
                <UploadResultArea goodCodes={goodCodes} badCodes={badCodes} id={id} onNewUpload={resetCodes}/>
            }
        </div>
    );
};

export default inject('stores')(observer(UploadCodesComponent));