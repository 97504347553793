import './GenCodesComponent.css';

import React, { useState } from 'react';
import Slider from '@material-ui/core/Slider';
import { inject, observer } from 'mobx-react';

import MainStore from '../../../store/mainStore';
import GenSuccessModal, { useGenSuccessModal } from '../GenSuccessModal';
import Spinner from '../../Spinner';

interface ThisComponentProps {
    stores?: MainStore;
    id: number;
}

const GenCodesComponent = ({ stores, id }: ThisComponentProps) => {
    const { productStore } = stores!;

    const [codeLength, setCodeLength] = useState<any>(6); //TODO type
    const [isNumeric, setIsNumeric] = useState(true);
    const [isLiteral, setIsLiteral] = useState(true);
    const [quantity, setQuantity] = useState(200);

    const [isGenerating, setIsGenerating] = useState(false);

    const genCodes = async() => {
        if (!isLiteral && !isNumeric ) {
            alert('Check numeric or letters characters');
            return;
        }

        setIsGenerating(true);

        const type = isLiteral && isNumeric ? 'alphanumeric' :
            isLiteral ? 'alpha' : 'numeric';

        try {
            const result = await productStore.genProductCodes(id, quantity, codeLength, type);

            handleCodes(result.good);

            showGenSuccessModal();
        } catch (e) {
            console.log(e);
        } finally {
            setIsGenerating(false);
        }
    }

    const handleCodes = (codes: string[]) => {
        let csvContent = "data:text/csv;charset=utf-8,"
            + codes.join("\n");
        const encodedUri = encodeURI(csvContent);

        const link = createDownloadLink(encodedUri);

        link.click();
    };

    const createDownloadLink = (encodedUri) => {
        const link = document.createElement('a');
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "codes.csv");
        document.body.appendChild(link);
        return link;
    };

    const {
        isGenSuccess,
        showGenSuccessModal,
        closeGenSuccessModal
    } = useGenSuccessModal();

    return (
        <div className="generate-page">
            {isGenSuccess &&
                <GenSuccessModal onClose={closeGenSuccessModal} />
            }

            {isGenerating &&
                <Spinner />
            }
            <div className="d-flex align-items-start">
                <div className="codes-length">
                    <h3 className="generate-page-title">Code length</h3>
                    <span>from 4 to 16</span>
                    <div className="d-flex align-items-center">
                        <input type="number" className="length-input" style={{
                        }} value={codeLength} onChange={(e) => {setCodeLength(e.target.value)}}/>
                        <Slider
                            value={codeLength}
                            min={4}
                            max={16}
                            step={1}
                            aria-labelledby="Zoom"
                            onChange={(e, length) => {setCodeLength(length)}}
                            classes={{ root: 'length-slider' }}
                        />
                    </div>
                </div>
                <div className="characters">
                    <h3 className="generate-page-title">Used characters</h3>
                    <span>Check if you want numbers and characters</span>

                    <div className="characters-buttons">
                        <div className="character-btn-wrap">
                            <span className="character-btn-label">Letters</span>
                            <label className="switcher">
                                <input type="checkbox" onChange={() => setIsLiteral(!isLiteral)} checked={isLiteral} />
                                <div className="switcher-btn character-btn"  />
                            </label>
                        </div>

                        <div className="character-btn-wrap">
                            <span className="character-btn-label">Numbers</span>
                            <label className="switcher">
                                <input type="checkbox" onChange={() => setIsNumeric(!isNumeric)} checked={isNumeric} />
                                <div className="switcher-btn character-btn" />
                            </label>
                        </div>
                    </div>
                </div>

                <div className="codes-quantity">
                    <h3 className="generate-page-title">Codes quantity</h3>
                    <span>no more than 1000</span>
                    <input type="number" className="length-input codes-quantity-input" style={{
                    }} onChange={(e) => {
                        if (+e.target.value > 1000) {
                            return;
                        }
                        setQuantity(+e.target.value);
                    }} value={quantity} />
                </div>
            </div>
            <button className="btn btn-success btn-gen" onClick={genCodes}>Generate codes</button>
        </div>
    );
};

export default inject('stores')(observer(GenCodesComponent));