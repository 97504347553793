import './App.css';

import React from 'react';
import { Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import PrivateRoute from './components/Routing/PrivateRoute';
import { inject, observer } from 'mobx-react';

import Header from './components/Header';
import ProductsPage from './pages/ProductsPage';
import ProductEditPage from './pages/ProductEditPage';
import SettingsPage from './pages/SettingsPage';
import ThankPage from './pages/ThankPage';
import SmsPage from './pages/SmsPage';
import CodesPage from './pages/CodesPage';
import MerchantRegistration from './pages/MerchantRegistration';
import { setIconUrlVariables } from './services/productService';
import errorStore from './store/errorStore';
import ErrorModal from './components/ErrorModal';
import MainStore from './store/mainStore';

interface AppProps {
    stores?: MainStore;
}

function App({ stores }: AppProps) {
    setIconUrlVariables();
    const { error } = stores!.errorStore;

    return (
        <Router>
            <div className="App">
                <Header />

                {error &&
                    <ErrorModal onClose={() => errorStore.clearError()} message={error.message} />
                }

                <Switch>
                    <PrivateRoute path="/products/" component={ProductsPage} exact />

                    <PrivateRoute path="/products/:id/thanks/sms-text" component={SmsPage} exact/>

                    <PrivateRoute path="/products/:id/thanks" component={ThankPage} exact/>

                    <PrivateRoute path="/products/:id/codes" component={CodesPage} exact/>

                    <PrivateRoute path="/products/:id" component={ProductEditPage} />

                    <PrivateRoute path="/settings/" component={SettingsPage} />

                    <PrivateRoute path="/register/" component={MerchantRegistration} />

                    <Redirect from={'/'} to={'products/'} />
                </Switch>
            </div>
        </Router>
    );
}

export default inject('stores')(observer(App));
