import './SwitcherCheckbox.css';

import React, {CSSProperties, useState} from 'react';
import { inject, observer } from 'mobx-react';

import MainStore from '../../store/mainStore';

interface SwitcherComponentProps {
    stores?: MainStore;
    prodProp: string;
    style?: CSSProperties;
}

const SwitcherCheckbox = ({ stores, prodProp, style }: SwitcherComponentProps ) => {
    const { productStore } = stores!;
    const { product } = productStore;

    const [ checked, setChecked ] = useState<boolean>(!!product[prodProp]);

    return (
        <label className="switcher" style={style}>
            <input
                type="checkbox"
                checked={checked}
                onChange={() => {
                    product[prodProp] = product[prodProp] ? 0 : 1;
                    setChecked(!checked);
                }}/>
            <div className="switcher-btn" />
        </label>
    );
};

export default inject('stores')(observer(SwitcherCheckbox));