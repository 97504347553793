import axios, {AxiosInstance} from 'axios';
import config from '../config';
import {getAuth0Client} from '../react-auth0-spa';
import RPCError from '../models/RPCError';
import errorStore from './errorStore';

export class ShopApi {
    private http: AxiosInstance;

    constructor(url: string) {
        this.http = axios.create({
            baseURL: url,
        });

        // intercept all outgoing requests
        this.http.interceptors.request.use(ShopApi.beforeRequest);

        // intercept all incoming responses
        this.http.interceptors.response.use(ShopApi.onRequestSuccess, ShopApi.onRequestFailure);
    }

    private static async beforeRequest(request) {
        const auth0 = await getAuth0Client();
        const token = await auth0.getTokenSilently();

        // add an auth header
        request.headers.Authorization = `Bearer ${token}`;

        return request;
    }

    private static onRequestSuccess(response) {
        if (response.data.error) {
            errorStore.addError(response.data.error);
            throw new RPCError(response.data.error.message, response.data.error.code);
        }

        return response.data.result;
    }

    private static async onRequestFailure(err) {
        console.log(err);
        // check for the `401` status and force-logout, otherwise proceed with regular workflow
        if (err.response.status === 401) {
            console.log('GLOBAL LOGOUT!');
            const auth0 = await getAuth0Client();
            await auth0.logout();
        } else {
            console.log('fetch failed', err);
        }

        throw err;
    }

    private async jsonRpc(method, params): Promise<any> {
        return await this.http.post(
            '',
            {"jsonrpc":"2.0", "id": 1, method, params}
        );
    }

    getProduct = async (id: number): Promise<any> => {
        try {
            return await this.jsonRpc('getProduct', [id]);
        } catch (e) {
            console.log('fetch failed', e);
            throw(e);
        }
    };

    getProducts = async (): Promise<any> => {
        try {
            return await this.jsonRpc('getProducts', []);
        } catch (e) {
            console.log('fetch failed', e);
            throw(e);
        }
    };

    registerMerchant = async (authId: string, name: string): Promise<any> => {
        try {
            return await this.jsonRpc('registerMerchant', [authId, name]);
        } catch (e) {
            console.log('fetch failed', e);
            throw(e);
        }
    };

    previewMerchantUrlName = async (name: string): Promise<string> => {
        try {
            return await this.jsonRpc('previewMerchantUrlName', [name]);
        } catch (e) {
            console.log('fetch failed', e);
            throw(e);
        }
    };

    getMerchant = async (): Promise<any> => {
        try {
           return await this.jsonRpc('getMerchant', []);
        } catch (e) {
            if (e instanceof RPCError && e.code === 404) {
                return null;
            } else {
                console.log('fetch failed', e);
                throw(e);
            }
        }
    };

    setMerchantLogo = async (
        id: number,
        logo: string,
        logoBody: string|null|ArrayBuffer = null
    ): Promise<any> => {
        try {
            return await this.jsonRpc('setMerchantLogo', [
                id,
                logo,
                logoBody
            ]);
        } catch (e) {
            console.log('fetch failed', e);
            throw (e);
        }
    };

    editProduct = async (
        id: number,
        type: ProductType,
        name: string,
        description: string,
        totalPrice: number,
        basePrice: number,
        serviceFee: number,
        productPrice: number,
        taxValue: number,
        taxClass: number,
        deliveryUrl: string,
        isHidden: number,
        isPublished: number,
        showServiceFee: number,
        uiMajorColor: string,
        uiMinorColor: string,
        uiMajorTextColor: string,
        uiMinorTextColor: string,
        tpSuccessTitle: string,
        tpSuccessText: string,
        tpSuccessInstructionsText: string,
        tpSuccessInstructionsButton: string,
        tpShowShare: number,
        tpShowReceipt: number,
        tpShowInstructions: number,
        smsText: string,
        image: string,
        imageBody: string|null|ArrayBuffer = null
    ): Promise<any> => {
        try {
            return await this.jsonRpc('editProduct', [
                id,
                type,
                name,
                description,
                totalPrice,
                basePrice,
                serviceFee,
                productPrice,
                taxValue,
                taxClass,
                deliveryUrl,
                isHidden,
                isPublished,
                showServiceFee,
                uiMajorColor,
                uiMinorColor,
                uiMajorTextColor,
                uiMinorTextColor,
                tpSuccessTitle,
                tpSuccessText,
                tpSuccessInstructionsText,
                tpSuccessInstructionsButton,
                tpShowShare,
                tpShowReceipt,
                tpShowInstructions,
                smsText,
                image,
                imageBody
            ]);
        } catch (e) {
            console.log('fetch failed', e);
            throw (e);
        }
    };

    addProduct = async (
        merchantId: number,
        type: ProductType,
        name: string,
        description: string,
        totalPrice: number,
        basePrice: number,
        serviceFee: number,
        productPrice: number,
        taxValue: number,
        taxClass: number,
        deliveryUrl: string,
        isHidden: number,
        isPublished: number,
        showServiceFee: number,
        uiMajorColor: string,
        uiMinorColor: string,
        uiMajorTextColor: string,
        uiMinorTextColor: string,
        tpSuccessTitle: string,
        tpSuccessText: string,
        tpSuccessInstructionsText: string,
        tpSuccessInstructionsButton: string,
        tpShowShare: number,
        tpShowReceipt: number,
        tpShowInstructions: number,
        smsText: string,
        image: string,
        imageBody: string|null|ArrayBuffer = null
    ): Promise<any> => {
        try {
            return await this.jsonRpc('addProduct', [
                merchantId,
                type,
                name,
                description,
                totalPrice,
                basePrice,
                serviceFee,
                productPrice,
                taxValue,
                taxClass,
                deliveryUrl,
                isHidden,
                isPublished,
                showServiceFee,
                uiMajorColor,
                uiMinorColor,
                uiMajorTextColor,
                uiMinorTextColor,
                tpSuccessTitle,
                tpSuccessText,
                tpSuccessInstructionsText,
                tpSuccessInstructionsButton,
                tpShowShare,
                tpShowReceipt,
                tpShowInstructions,
                smsText,
                image,
                imageBody
            ]);
        } catch (e) {
            console.log('fetch failed', e);
            throw (e);
        }
    };

    addProductCodes = async (id: number, codes: string[]) => {
        try {
            return await this.jsonRpc('addProductCodes', [id, codes]);
        } catch (e) {
            console.log('fetch failed', e);
            throw (e);
        }
    };

    genProductCodes = async (id: number, quantity: number, length: number, type: 'alpha'|'numeric'|'alphanumeric'): Promise<any> => {
        try {
            return await this.jsonRpc('genProductCodes', [id, quantity, length, type]);
        } catch (e) {
            console.log('fetch failed', e);
            throw (e);
        }
    };
}

const shopApi = new ShopApi(config.api.url);

export default shopApi;
