import './ProductEditPage.scss';

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-autosize-textarea';

import { PriceModal, usePriceModal, VatClassesModal, useVatModal, ImageCropperModal, useImageCropper, ColorSchemeModal, useColorSchemeModal } from '../../components/ProductEditModals';
import MainStore from '../../store/mainStore';
import ProductModel from '../../models/ProductModel';
import HTMLAndTextEditor from '../../components/HTMLAndTextEditor';
import { formatToStringView, setColorScheme } from '../../services/productService';
import Spinner from '../../components/Spinner';
import ProductTypeSelect from '../../components/ProductTypeSelect';

interface ThisRouteParams {
    id: string,
}

interface ThisComponentProps extends RouteComponentProps<ThisRouteParams> {
    stores: MainStore,
}

const ProductEditPage = ({ match, stores, history }: ThisComponentProps ) => {
    const { t } = useTranslation();
    const { productStore } = stores;
    const id = localStorage.getItem("id");

    let product: ProductModel = productStore.product;

    useEffect((): void|(() => void) => {
        productStore.setProductFromLocalStorage('editedProduct');
        setColorScheme(productStore.product);
        id !== 'new' ? history.push(`/products/${product.id}`) : history.push(`/products/new`);
    }, [productStore]);

    const {
        isPriceModalShowed,
        showPriceModal,
        closePriceModal,
        savePrice
    } = usePriceModal(product);

    const {
        isVatModalShowed,
        showVatModal,
        saveVatClass,
        closeVatModal
    } = useVatModal(product);

    const {
        isDraggable,
        imageProps,
        onCropChange,
        onCropComplete,
        onZoomChange,
        setImageZoom,
        processImage,
        loadImage,
        isProcessing,
        closeImageModal
    } = useImageCropper(product);

    const {
        isColorPickerShowed,
        showColorPicker,
        closeColorPicker,
        saveChosenColor,
        setUsedScheme,
        colorInputs
    } = useColorSchemeModal(product, productStore);

    const onNextClick = () => {
        localStorage.setItem('editedProduct', JSON.stringify(product));
        history.push(`${match.url}/thanks`);
    };

    const onResetProduct = async () => {
        productStore.setProductFromLocalStorage('initialProduct');
        setColorScheme(productStore.product);

        localStorage.setItem('editedProduct', JSON.stringify(product));
    };

    return (
        <div className="lift-body">
            {isProcessing &&
                <Spinner />
            }

            {!isDraggable &&
                <>
                    <div className="fix-btn-wrap" style={{top: "110px"}}>
                        <h2 style={{color: '#343a40', marginBottom: '10px'}}>{t('editPage.chooseType')}</h2>

                        <ProductTypeSelect product={product} />
                    </div>
                    <div className="fix-btn-wrap" style={{bottom: "125px"}}>
                        <button
                            className="btn btn-outline-dark btn-rounded aside-btn"
                            onClick={() => showColorPicker()}
                        >{t('button.changeColors')}</button>

                        {isColorPickerShowed &&
                            <ColorSchemeModal
                                onClose={closeColorPicker}
                                onSave={saveChosenColor}
                                setUsedScheme={setUsedScheme}
                                colorInputs={colorInputs}
                            />
                        }
                    </div>
                    <div className="fix-btn-wrap">
                        <button
                            className="btn btn-outline-danger btn-rounded aside-btn"
                            onClick={onResetProduct}
                        >{t('button.reset')}</button>
                    </div>
                    <div className="fix-btn-wrap fix-btn-wrap_right">
                        <button
                            className="btn btn-success btn-rounded aside-btn"
                            onClick={onNextClick}
                        >{t('button.next')}</button>
                    </div>
                </>
            }

            <div className="product-edit-page sm-lift-wrap">
                {isDraggable &&
                    <ImageCropperModal
                        imageProps={imageProps}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={onZoomChange}
                        setImageZoom={setImageZoom}
                        onSave={processImage}
                        onCancel={closeImageModal}
                    />
                }

                <div className={isDraggable ? 'sm-lift sm-lift-overflow-hidden' : 'sm-lift'} style={{backgroundImage: `url(${product.image})`}} >
                    {isPriceModalShowed &&
                        <PriceModal
                            product={product}
                            onSave={savePrice}
                            onClose={closePriceModal}
                        />
                    }

                    <div className="file-input-wrap">
                        <input className="file-input"
                               type="file"
                               accept="image/*"
                               onChange={loadImage}/>
                    </div>

                    <div className="sm-floor sm-floor-product-top" id="landing">

                        <div className="sm-product-panel">
                            <div className="sm-product-title">
                                <TextareaAutosize
                                    defaultValue={product.name}
                                    onChange={(e) => {product.name = e.currentTarget.value}}
                                    className="sm-product-text_editable sm-product-title_editable"
                                    disabled={isDraggable}
                                />
                            </div>

                            <div className="sm-product-price-wrap">
                                <div
                                    className="sm-product-price"
                                    onClick={() => {
                                        if (isColorPickerShowed || isDraggable) return;
                                        showPriceModal();
                                    }}
                                >{formatToStringView(product.totalPrice)} €</div>

                                {!!product.showServiceFee &&
                                    <div className="sm-product-price-comment">Hinta pitää sisällään mobiilimaksulisän.</div>
                                }

                            </div>

                            <div className="sm-product-buttons sm-order-inner">
                                <button
                                    type="button"
                                    id="payment_btn"
                                    className="sm-button"
                                >OSTA</button>
                            </div>

                            <div>
                                <div className="sm-next-text">Siirry alaspäin</div>
                                {!isDraggable &&
                                    <a className="sm-next" href="#description"/>
                                }
                            </div>

                        </div>
                    </div>

                    <div className="sm-floor" id="description">
                        <div
                            className="sm-product-panel"
                        >
                            <div className="sm-product-description">
                                <HTMLAndTextEditor
                                    product={product}
                                    editedProductField={'description'}
                                />
                            </div>

                            <div>
                                <div className="sm-product-tax">
                                    <span onClick={() => showVatModal()}>{product.taxText}</span>
                                    {isVatModalShowed &&
                                        <VatClassesModal
                                            currentTaxClass={product.taxClass}
                                            onSave={saveVatClass}
                                            onClose={closeVatModal}
                                        />
                                    }
                                </div>

                                <div className="sm-product-info-box">
                                    <div className="sm-product-info-box-logos">
                                        <span className="sm-product-info-box-logo sm-product-info-box-logo-mm"></span>
                                        <span className="sm-product-info-box-logo sm-product-info-box-logo-elisa"></span>
                                        <span className="sm-product-info-box-logo sm-product-info-box-logo-telia"></span>
                                        <span className="sm-product-info-box-logo sm-product-info-box-logo-moi"></span>
                                        <span className="sm-product-info-box-logo sm-product-info-box-logo-dna"></span>
                                    </div>
                                    Tämä maksutapa on estetty Wifi yhteyden ollessa päällä. Ostokset lisätään käytetyn datayhteyden liittymän laskuun.
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default inject('stores')(observer(ProductEditPage));
